import React, { useContext, useRef, useState, useEffect } from "react";
import './Header.scss';
import {
  Link,
} from "react-router-dom";
import TopBar from 'components/TopBar/TopBar';
import { useQuery } from '@apollo/client';
import Conf from 'utils/Conf';
import SlugLink from 'components/Utils/SlugLink';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function Header() {
  const { ariane, root, pathname, isPage, screenSize } = useContext(SettingsContext);
  const [ scrollY, setScrollY ] = useState(0);
  const handleScroll=()=>{
      setScrollY(window.scrollY);
  };
  useEffect(()=>{
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[]);
  const ref=useRef();
  let classes="header";
  let threshold=360;
  if (ref.current) threshold=ref.current.clientHeight-49;
  if (!root && ['/actualites','/actualites-archives'].indexOf(pathname)===-1) classes+=" racine";
  if (pathname==="/") classes+=" home";
  Conf.special.forEach((spe)=>{
      if (root===spe.id) classes+=' '+spe.class;
  });
  let headerArticle=false;
  if (ariane[ariane.length-1] && ariane[ariane.length-1]['__typename']==='articles') headerArticle=true;
  if (ariane[ariane.length-1] && ariane[ariane.length-1]['__typename']==='rubriques') headerArticle=true;
  if (isPage) headerArticle=true;
  if (headerArticle) classes+=" header-article";
  classes+=" header-"+screenSize;
  let alwaysSmall=screenSize==="xs" && headerArticle;
  if (scrollY>threshold || alwaysSmall) classes+=" small";
  useEffect(()=>{
    setScrollY(window.scrollY);
    const htmlTags = document.getElementsByTagName("html");
    const condition = !(
      (ariane[ariane.length-1] && ariane[ariane.length-1]['__typename']==='articles')
      || (ariane[ariane.length-1] && ariane[ariane.length-1]['__typename']==='rubriques')
      || isPage
    );
    htmlTags.item(0).style.scrollBehavior = "auto";
    setTimeout(()=>{
        window.scrollTo(0,0);
        setScrollY(window.scrollY);
        if (condition) {
            htmlTags.item(0).style.scrollBehavior = "smooth";
        } else {
            htmlTags.item(0).style.scrollBehavior = "auto";
        }
    },1);
  },[ariane,root,isPage]);

  let rubriqueId=( ariane[0] && ariane[0]['__typename']==='rubriques' && ariane[0].id ) || null;
  const { refetch,networkStatus,data } = useQuery(Queries.header,{
    variables: { id:parseInt(root) || 0 },
  });
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const uuid=`header${parseInt(root) || 0}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const rubriqueRoot=data ? data.rubriques[0] : null;
  const visuel=rubriqueRoot ? (rubriqueRoot.visuel ? `url(${Conf.apiUrl}/assets/${rubriqueRoot.visuel.id}?key=bandeau-wide)` : null) : null;
  const isSpecial=Conf.special.reduce((spe,acc)=>acc || root===spe.id,false);
  return pathname!=="/preview" ? <div className={classes} ref={ref}>
    <div className="header-background"></div>
    {!alwaysSmall && <div className="line"></div>}
    {!alwaysSmall && <div className="deco">
        <div className="deco1"></div>
        <div className="deco2"></div>
    </div>}
    {!alwaysSmall && <div className="logo-container">
        {rubriqueRoot && ariane.length===0 && <SlugLink item={rubriqueRoot}><div className="rubrique-header">
            <div className="rubrique-header-inner" style={{color:rubriqueRoot.color_title,backgroundImage:visuel}}>
              {rubriqueRoot.show_titre && <h3>{rubriqueRoot.titre}</h3>}
            </div>
        </div></SlugLink>}
        {(!(rubriqueRoot && ariane.length===0) || ( rubriqueRoot && isSpecial)) &&
            <div className="logo">
                <div className="logo-circle"></div>
                <Link to='/'>
                <div className="logo-img">
                  <span>TARN</span>
                </div>
                </Link>
            </div>}
    </div>}
    <TopBar root={root} rubriqueId={rubriqueId} pathname={pathname} rubriqueRoot={rubriqueRoot}/>
  </div> : '';
}

export default Header;
