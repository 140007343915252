import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Preview from 'components/Preview';
import Accueil from 'components/Accueil/Accueil';
import Carte from 'components/Carte/Carte';
import NotFound from 'components/NotFound';
import Actualites from 'components/Actualites/Actualites';
import ActualitesArchives from 'components/Actualites/ActualitesArchives';
import SubRoutes from "components/Misc/SubRoutes";
import Background from 'components/Background';
import Foreground from 'components/Foreground';
import SettingsProvider from "Providers/SettingsProvider";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e1f0f7',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#016a66',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      // Name of the rule
      indicator: {
        // Some CSS
        height: 8,
      },
    },
  },
});

function App({subRoutes,clientSystem}) {
  const localRoutes=[
    <Route key='carte' exact path="/carte">
      <Carte/>
    </Route>,
    <Route key='actualites' exact path="/actualites">
      <Actualites/>
    </Route>,
    <Route key='actualites-archives' exact path="/actualites-archives">
      <ActualitesArchives/>
    </Route>,
    <Route key='preview' exact path="/preview">
      <Preview/>
    </Route>,
    <Route key='main' exact path="/">
      <Accueil/>
    </Route>,
    <Route key='biocybele' exact path="/biocybele">
      <Redirect to="/evenements/biocybele"/>
    </Route>,
    <Route key='notfound' component={NotFound} />
  ]
  return (
      <ThemeProvider theme={theme}>
      <Router>
        <SettingsProvider clientSystem={clientSystem}>
        <div className="body">
        <Background/>
        <Foreground>
          <Header/>
          <div className="routes-container">
          <SubRoutes root={true} localRoutes={localRoutes}/>
          </div>
        </Foreground>
        </div>
        <Footer/>
        </SettingsProvider>
      </Router>
      </ThemeProvider>
  );
}

export default App;
