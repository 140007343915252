import React, { useContext, useEffect, useState } from "react";
import './Footer.scss';
import { useQuery } from '@apollo/client';
import Conf from 'utils/Conf';
import SlugLink from 'components/Utils/SlugLink';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Utils from 'utils/Utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function Footer() {
  const { refetch,networkStatus,data } = useQuery(Queries.footer);
  const { t,updateLoadState,fetchedList } = useContext(SettingsContext);
  const uuid='footer';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const partenaires=data ? data.partenaires.reduce((rv, x)=>{
    (rv[x.type.type] = rv[x.type.type] || []).push(x);
    return rv;
  }, {}) : {};
  const rubriques=data ? data.rubriques : [];
  const mentions=data ? data.site.mentions : '';
  const [ showMentions,setShowMentions ] = useState(false);
  const mentionsClasses=showMentions ? 'mentions-content show' :'mentions-content';
  useEffect(()=>{
    setTimeout(()=>window.scrollTo({
      top: document.getElementById('root').clientHeight - window.innerHeight,
      left: 0,
      behavior: 'smooth'
    }),10);
  },[showMentions]);
  return data ? <div className='footer'>
      <Container maxWidth={null}>
        <Grid container spacing={3} className="flex-center">
          <Grid item xs={12}>
            <Grid container spacing={3} className="flex-center">
            {rubriques.map((r)=>{
              const list=[...r.rubriques, ...r.articles];
              const center=r.id===5 ? ' center':'';
              return <Grid key={r.id} item xs={6} lg={4} xl={3}>
              <div className='footer-plan'>
              <div className={'footer-plan-menu-titre'+center}><SlugLink item={r}>{r.titre}</SlugLink></div>
                {list.length>0 && <ul key={'list-'+r.id}>
                  {list.map((item)=><li key={item.id}>
                    <SlugLink item={item}>{item.titre}</SlugLink>
                  </li>)}
                </ul>}
              </div>
              </Grid>;
            })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr/>
            <Grid container spacing={3} className="flex-center">
            {Object.keys(partenaires).map((type)=><Grid key={type} item xs={12} lg={6}>
              <div>
                <h4>{type}</h4>
                <Grid container spacing={0} className="flex-center">
                  {partenaires[type].map((p)=><Grid key={p.id} item xs={3} className='footer-partenaire'>
                    <div className='footer-partenaire-logo'><img src={`${Conf.apiUrl}/assets/${p.logo.id}?key=logo`} alt=''/></div>
                    <div className='footer-partenaire-nom'>{p.nom}</div>
                  </Grid>)}
                </Grid>
              </div>
            </Grid>)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr/>
            {mentions && <div className='footer-mentions'>
            <h3>{t("footer-mentions")} <span>{showMentions ? <ExpandLessIcon onClick={()=>setShowMentions((b)=>!b)}/> : <ExpandMoreIcon onClick={()=>setShowMentions((b)=>!b)}/>}</span></h3>
            <div className={mentionsClasses}>{Utils.parseWithLinks(mentions)}</div>
            </div>}
          </Grid>
        </Grid>
    </Container>
  </div> : '';
}

export default Footer;
