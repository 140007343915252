import React from "react";
import './Blocs.scss';
import Grid from '@material-ui/core/Grid';
import Conf from 'utils/Conf';

function BlocImages({item}) {
  const {images,crop,lien} = item || {};
  const key= crop===false ? 'large' : '16-9-crop';
  return <div className="bloc bloc-images">
  <Grid container spacing={2} justify="center">
    {images.map((img,i)=>{
      const imageId=img.directus_files_id.id || img.directus_files_id;
      const sm=Math.max(6,Math.floor(12/images.length));
      const md=Math.max(4,Math.floor(12/images.length));
      const lg=Math.max(3,Math.floor(12/images.length));
      const image=<img src={`${Conf.apiUrl}/assets/${imageId}?key=${key}`} alt=""/>
      return <Grid className="images-container" item xs={12} sm={sm} md={md} lg={lg} key={imageId}>
          {lien ? <a href={`${Conf.apiUrl}/assets/${imageId}`} target="_blank" rel="noreferrer">{image}</a> : image}
      </Grid>;
    })}
  </Grid>
  </div>;
}
export default BlocImages;
