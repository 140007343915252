import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import Encart from 'components/Encart';
import Anchor from 'components/Misc/Anchor';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function Asso() {
    const { refetch,networkStatus,data } = useQuery(Queries.asso);
    const { updateLoadState,fetchedList } = useContext(SettingsContext);
    const uuid='asso';
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const assoRub=data ? data.rubriques[0] : null;
    return data ? <>
        <Anchor slug={assoRub.slugs[0].slug} rubId={1}/>
        <Encart data={{titre:assoRub.titre,texte:assoRub.descriptif,articles:assoRub.articles}} template='generique' side='left'/>
    </> : '';
}

export default Asso;
