import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
const nbsp=(text)=>{
  let res=text+'';
  res=res.replace(/[ ]+!/gm,"&nbsp;!");
  res=res.replace(/[ ]+\?/gm,"&nbsp;?");
  res=res.replace(/[ ]+:/gm,"&nbsp;:");
  res=res.replace(/[ ]+;/gm,"&nbsp;;");
  return res;
}
const parseWithLinks = (text)=>{
  if (text) {
    let res=nbsp(text);
    const options = {
      replace: ({ name, attribs, children }) => {
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('://')===-1
          && attribs.href.indexOf('mailto:')===-1
        ) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
      }
    };
    return parse(res, options);
  }
  return '';
}
const cumulativeOffset = (element)=>{
    let top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);
    return {
        top: top,
        left: left
    };
}
const jump = (h)=>{
    const node = document.getElementById(h);
    let top=0;
    if (node) {
        top=cumulativeOffset(node).top;
    }
    window.scrollTo(0, top-15);
}
const path2type = (path)=>{
    const tab=path.split('/');
    const last=tab[tab.length-1];
    const tabLast=last.split('-');
    return tabLast[0];
}
const utils={
    jump,
    cumulativeOffset,
    path2type,
    parseWithLinks,
    nbsp,
}
export default utils;
