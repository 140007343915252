import React, { useContext, useEffect } from "react";
import './Engager.scss';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import SlugLink from 'components/Utils/SlugLink';
import Anchor from 'components/Misc/Anchor';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';

function Engager({titre=true}) {
  const { refetch,networkStatus,data } = useQuery(Queries.engager);
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const uuid='engager';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const articles=data ? data.articles : [];
  const rub=data ? data.rubriques[0] : {};
  return <>
      {rub.slugs && <Anchor slug={rub.slugs[0].slug} rubId={4}/>}
      <div className="engager">
      <Grid container spacing={3} justify="center">
          {titre && <Grid item xs={12} align="center">
              <h3 className="engager-titre">{articles[0] ? articles[0].rubrique.titre : ''}</h3>
          </Grid>}
          {articles.map((art,i)=><Grid item xs={12} md={6} lg={4} key={art.id} className={"engager-art-item engager-art-item-"+i}>
              <SlugLink item={art}>
                  <div className="engager-art">
                      <h3>{Utils.parseWithLinks(art.titre)}</h3>
                  </div>
              </SlugLink>
          </Grid>)}
      </Grid>
  </div>
  </>;
}

export default Engager;
