import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import SlugLink from 'components/Utils/SlugLink';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function SousMenu({ anchorEl, id, show, onClick }) {
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.sousmenu,{
    variables: { id },
  });
  const uuid=`sousmenu${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const rubriques = data ? data.rubriques : [];
  const articles = data ? data.articles : [];
  const { left, width } = anchorEl.getBoundingClientRect();
  const style = {
    top:0,
    left:left,
    fontSize:'20px',
    color:'#016a66',
    minWidth:width,
  }
  const classes=`sousmenu${show ? ' show' : ''}`;
  return <ul className={classes} style={style} onClick={onClick}>
  {articles && articles.map((a)=>{
      return <li key={a.id}><SlugLink item={a}>{a.titre}</SlugLink></li>
  })}
  {rubriques && rubriques.map((r)=>{
      return <li key={r.id}><SlugLink item={r}>{r.titre}</SlugLink></li>
  })}
  </ul>;
}

export default SousMenu;
