import React, { useState, createRef, useEffect, useContext } from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import SousMenu from 'components/TopBar/SousMenu';
import './TopBar.scss';
import { useQuery } from '@apollo/client';
import SlugLink from 'components/Utils/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  Link
} from "react-router-dom";

function TopBar({ rubriqueId, rubriqueRoot, pathname, root=null }) {
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(root ? Queries.topbar : Queries.topbarroot,{
    variables: { id:root },
  });
  const uuid=`topbar${root ? root : ''}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const [value, setValue] = useState(0);
  const [rubriques, setRubriques] = useState([]);
  const [open, setOpen] = useState(null);
  const [anchorEls, setAnchorEls] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openXs = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setOpen(newValue);
  };
  useEffect(()=>{
    const rubs=data ? data.rubriques : [];
    setRubriques(rubs);
    setAnchorEls(rubs.map(() => createRef()));
    setValue(Math.max(rubs.indexOf(rubs.find((o)=>o.id===rubriqueId)),0));
  },[data,rubriqueId]);
  const { currentRub, t } = useContext(SettingsContext);
  const setToCurrentRub= ()=>{
      const idx=Math.max(rubriques.findIndex((rub)=>rub.id===currentRub),0);
      setValue(idx);
  }
  useEffect(setToCurrentRub,[currentRub,rubriques]);
  let xsRes=[<MenuItem key={'close'} onClick={handleClose} className="menu close">
            <span><CloseIcon/></span>
  </MenuItem>]
  rubriques.forEach((rubrique,i)=>{
      if (rubriqueId!==null || pathname.startsWith("/actualites")) {
          xsRes.push(<MenuItem key={'rub'+rubrique.id} onClick={handleClose} className="menu">
            <SlugLink item={rubrique}>{rubrique.titre}</SlugLink>
          </MenuItem>);
      } else {
          xsRes.push(<MenuItem key={'rub'+rubrique.id} onClick={handleClose} className="menu">
            <a href={`#${rubrique.slugs[0] ? rubrique.slugs[0].slug : rubrique.id}`}>{rubrique.titre}</a>
          </MenuItem>);
      }
      rubrique.rubriques.forEach((rub,i)=>{
        xsRes.push(<MenuItem key={'rub'+rub.id} onClick={handleClose} className="menu ss-menu">
          <SlugLink item={rub}>{rub.titre}</SlugLink>
        </MenuItem>);
      });
      rubrique.articles.forEach((art,i)=>{
        xsRes.push(<MenuItem key={'art'+art.id} onClick={handleClose} className="menu ss-menu">
          <SlugLink item={art}>{art.titre}</SlugLink>
        </MenuItem>);
      });
  });
  return <AppBar position="static" className="topbar" onMouseLeave={()=>{setOpen(null); setToCurrentRub();}}>
          <Hidden xsDown>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="menu"
                centered
              >
                {rubriques.map((rubrique,i)=>{
                  return rubriqueId!==null || pathname.startsWith("/actualites") ?
                  <Tab key={rubrique.id} onMouseEnter={(e)=>handleChange(e,i)} item={rubrique} label={rubrique.titre} ref={anchorEls[i]} component={SlugLink}></Tab>
                  :
                  <Tab key={rubrique.id} onMouseEnter={(e)=>handleChange(e,i)} href={`#${rubrique.slugs[0] ? rubrique.slugs[0].slug : rubrique.id}`} label={rubrique.titre} ref={anchorEls[i]} component={"a"}></Tab>;
              })}
              </Tabs>
              <div className="sousmenus">
              {rubriques.map((rubrique,i)=>anchorEls[i].current ? <SousMenu key={rubrique.id} onClick={()=>setOpen(null)} show={open===i} id={rubrique.id} anchorEl={anchorEls[i].current || null}/>: '')}
              </div>
          </Hidden>
          <Hidden smUp>
            <div className="xs-menu">
              {rubriqueRoot && <div className="xs-main-link"><SlugLink item={rubriqueRoot}>{rubriqueRoot.titre}</SlugLink></div>}
              {root===null && <div className="xs-main-link"><Link to="/">{t('nature-et-progres-tarn')}</Link></div>}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openXs}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 'calc(100% - 32px)',
                    maxWidth: 'calc(100% - 32px)',
                  },
                }}
              >
                {xsRes}
              </Menu>
            </div>
          </Hidden>
          <Link to='/'>
          <div className="logo-img" onClick={()=>window.scrollTo(0,0)}>
          </div>
          </Link>
        </AppBar>;
}

export default TopBar;
