import { gql } from '@apollo/client';
const queries={
  article: gql`
    query Article($id: Float){
        articles(filter: { id : { _eq: $id}}) {
          id
          titre
          texte
          blocs {
            id
            sort
            item {
              ... on bloc_diaporama {
                images {
                  id
                  directus_files_id {
                    id
                  }
                }
              }
              ... on bloc_images {
                images {
                  id
                  directus_files_id {
                    id
                  }
                }
                crop
                lien
              }
              ... on bloc_texte {
                texte
              }
              ... on bloc_texteimage {
                texte
                image {
                  id
                }
                document {
                  id
                }
                link
                side
              }
              ... on bloc_documents {
                label
                documents {
                  id
                  directus_files_id {
                    id
                    title
                    filesize
                    type
                  }
                }
              }
            }
          }
        }
    }
  `,
  accueil:gql`
    query Accueil{
        interface (filter: { key : { _in: ["jolie-phrase-une"]}}) {
          value
        },
        rubriques(filter: { type : { _eq: "menu"}}, sort: ["sort"]) {
          id
          titre
          type
          descriptif
          visuel {
              id,
          }
          detail {
              id,
          }
          articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
            id
            titre
            visuel {
                id,
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
          rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
            id
            titre
            visuel {
                id,
            }
            detail {
                id,
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
        slide (filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          titre
          lien
          image {
            id
            __typename
          }
          __typename
        }
    }
  `,
  cartoucheagenda:gql`
    query CartoucheAgenda($date: String) {
        interface (filter: { key : { _eq: "actualites"}}) {
          value
        },
        agenda (filter: {
          _and: [
            { status : { _eq: "Published"} },
            {_or: [
              { _and: [
                { date_to : { _nnull: true } },
                { date_to : { _gte: $date} },
              ]},
              { date_from : { _gte: $date} }
            ]}
          ]
        }, sort: "date_from" ) {
          id
          title
          resume
          date_from
          date_to
          affdate
          type {
            id
            type
            couleur
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  asso:gql`
    query Asso {
        interface (filter: { key : { _in: ["carte-interactive", "des-professionnels"]}}) {
          value
        },
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: 1 } }) {
          id
          titre
          type
          descriptif
          color_title
          slugs (sort: "-date_created") {
              slug
          }
          visuel {
            id
          }
          detail {
              id,
          }
          articles(filter: { status : { _eq: "Published"}}) {
            id
            titre
            visuel {
                id,
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
      }
    }
  `,
  contact:gql`
    query Asso {
        interface (filter: { key : { _in: ["carte-interactive", "des-professionnels"]}}) {
          value
        },
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: 5 } }) {
          id
          titre
          type
          descriptif
          color_title
          slugs (sort: "-date_created") {
              slug
          }
          visuel {
              id
          }
          detail {
              id,
          }
      }
    }
  `,
  mention:gql`
    query Mention {
      interface (filter: { key : { _in: ["carte-interactive", "des-professionnels"]}}) {
        value
      },
      rubriques(filter: { status: { _eq: "Published" }, id : { _eq: 2 } }) {
        id
        titre
        type
        descriptif
        color_title
        slugs (sort: "-date_created") {
            slug
        }
        visuel {
            id
        }
        detail {
            id,
        }
        articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          visuel {
              id,
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
      }
    }
  `,
  engager:gql`
    query Engager{
        articles(filter: { status : { _eq: "Published"}, rubrique: {id :{ _eq:4 }}}) {
          id
          titre
          visuel {
              id,
          }
          rubrique {
              titre
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: 4 } }) {
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  evenements:gql`
    query Evenements{
        interface (filter: { key : { _in: ["les-evenements"]}}) {
          value
          texte
        },
        rubriques(filter: { status : { _eq: "Published"}, type : { _eq: "event"}}, sort: ["sort"]) {
          id
          titre
          type
          pitch
          descriptif
          visuel {
              id,
          }
          detail {
              id,
          }
          articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
            id
            titre
            visuel {
                id,
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
          rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
            id
            titre
            pitch
            descriptif
            visuel {
                id,
            }
            detail {
                id,
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
          slugs (sort: "-date_created") {
              slug
          }
      }
      rub: rubriques(filter: { status: { _eq: "Published" }, id : { _eq: 3 } }) {
          slugs (sort: "-date_created") {
              slug
          }
      }
    }
  `,
  header:gql`
    query RubriqueHeader($id: Float){
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: $id } }, sort: ["sort"]) {
          id
          titre
          type
          descriptif
          color_title
          sort
          show_titre
          slugs (sort: "-date_created") {
              slug
          }
          visuel {
              id
          }
          detail {
              id,
          }
      }
    }
  `,
  footer:gql`
    query Footer{
      rubriques(filter: { type : { _eq: "menu"}, status : { _eq: "Published"}}, sort: ["sort"]) {
        id
        titre
        articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
        }
        rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
        }
      }
      site {
        mentions
      }
      partenaires (filter: { status: { _eq: "Published" }}, sort: ["sort"]) {
        id
        nom
        type {
          id
          type
        }
        logo {
          id
        }
      }
    }
  `,
  ariane:gql`
    query Path{
        rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          type,
          rubrique(filter: { status : { _eq: "Published"}}) {
              id
          }
          rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
              id
              titre
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          rubrique {
              id
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  path:gql`
    query Path{
        rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          rubrique(filter: { status : { _eq: "Published"}}) {
              id
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          rubrique {
              id
          }
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  documents:gql`
    query Documents($ids: [String]){
        documents:files(filter: { id : { _in: $ids}}) {
          id
          title
          type
          filesize
        }
    }
  `,
  subroutes:gql`
    query Rubrique($id: Float){
        rubriques(filter: { status : { _eq: "Published"}, rubrique: { id: {_eq: $id}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}, rubrique: { id: {_eq: $id}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  subroutesroot:gql`
    query Routes{
        rubriques(filter: { status : { _eq: "Published"}, rubrique: { id: {_null:true}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}, rubrique: { id: {_null:true}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        agenda(filter: { status : { _eq: "Published"}}){
          id
          title
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  rubrique:gql`
    query Rubrique($id: Float){
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: $id } }, sort: ["sort"]) {
          id
          titre
          type
          descriptif
          color_title
          sort
          show_titre
          rubrique(filter: { status : { _eq: "Published"}}) {
            id
          }
          slugs (sort: "-date_created") {
              slug
          }
          visuel {
              id
          }
          detail {
              id,
          }
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          articles(filter: { status: { _eq: "Published" }}, sort: ["sort"]) {
            id
            titre
            visuel {
              id
            }
            slugs (sort: "-date_created") {
                slug
            }
          }
          rubriques(filter: { status: { _eq: "Published" }}, sort: ["sort"]) {
            id
            titre
            type
            descriptif
            color_title
            sort
            show_titre
            documents {
              id
              directus_files_id {
                id
                title
                filesize
                type
              }
            }
            slugs (sort: "-date_created") {
                slug
            }
            visuel {
                id
            }
            detail {
                id,
            }
            articles(filter: { status: { _eq: "Published" }}, sort: ["sort"]) {
              id
              titre
              visuel {
                id
              }
              slugs (sort: "-date_created") {
                  slug
              }
            }
          }
        }
    }
  `,
  sousmenu:gql`
    query SousMenu($id: Float){
        rubriques(filter: { status : { _eq: "Published"}, rubrique: { id: { _eq: $id } } }, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}, rubrique: { id: { _eq: $id } } }, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
    }
  `,
  topbar:gql`
    query Topbar($id: Float){
        rubriques(filter: { status : { _eq: "Published"}, rubrique: { id: {_eq:$id}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
          rubrique {
            id
          }
          rubriques(filter: { status : { _eq: "Published"} }, sort: ["sort"]) {
            id
            titre
            slugs (sort: "-date_created") {
                slug
            }
          }
          articles(filter: { status : { _eq: "Published"} }, sort: ["sort"]) {
            id
            titre
            slugs (sort: "-date_created") {
                slug
            }
          }
        }
    }
  `,
  topbarroot:gql`
    query TopbarRoot{
        rubriques(filter: { status : { _eq: "Published"}, rubrique: { id: {_null:true}}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
          rubrique {
            id
          }
          rubriques(filter: { status : { _eq: "Published"} }, sort: ["sort"]) {
            id
            titre
            slugs (sort: "-date_created") {
                slug
            }
          }
          articles(filter: { status : { _eq: "Published"} }, sort: ["sort"]) {
            id
            titre
            slugs (sort: "-date_created") {
                slug
            }
          }
        }
    }
  `,
  settings:gql`
    query Settings{
      interface {
        key
        value
        texte
      }
      rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
        id
        titre
        type,
        rubrique(filter: { status : { _eq: "Published"}}) {
            id
        }
        rubriques(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        slugs (sort: "-date_created") {
            slug
        }
      }
      articles(filter: { status : { _eq: "Published"}}, sort: ["sort"]) {
        id
        titre
        rubrique {
          id
          titre
          slugs (sort: "-date_created") {
              slug
          }
        }
        slugs (sort: "-date_created") {
            slug
        }
      }
    }
  `,
  actualites:gql`
    query Actualites($date: String){
      agenda (filter: {
        _and: [
          { status : { _eq: "Published"} },
          {_or: [
            { _and: [
              { date_to : { _nnull: true } },
              { date_to : { _gte: $date} },
            ]},
            { date_from : { _gte: $date} }
          ]}
        ]
      }, sort: "date_from" ) {
        id
        title
        resume
        text
        date_from
        date_to
        affdate
        visuel {
          id
        }
        type {
          id
          type
          couleur
        }
        slugs (sort: "-date_created") {
            slug
        }
      }
    evt_type {
      type
      couleur
    }
  }
  `,
  actualitesArchives:gql`
    query ActualitesArchives($date: String){
      agenda (filter: {
        _and: [
          { status : { _eq: "Published"} },
          {_or: [
            { _and: [
              { date_to : { _null: true } },
              { date_from : { _lt: $date} },
            ]},
            { date_to : { _lt: $date} }
          ]}
        ]
      }, sort: "-date_from" ) {
        id
        title
        resume
        text
        date_from
        date_to
        affdate
        visuel {
          id
        }
        type {
          id
          type
          couleur
        }
        slugs (sort: "-date_created") {
            slug
        }
      }
    evt_type {
      type
      couleur
    }
  }
  `,
  actualite:gql`
    query Actualite($id: Float){
      evt_type {
        id
        type
        couleur
      }
      agenda (filter: { status : { _eq: "Published"}, id : { _eq: $id}}) {
        id
        title
        resume
        text
        date_from
        date_to
        affdate
        visuel {
          id
        }
        type {
          id
          type
          couleur
        }
        slugs (sort: "-date_created") {
            slug
        }
        blocs {
          id
          sort
          item {
            ... on bloc_diaporama {
              images {
                id
                directus_files_id {
                  id
                }
              }
            }
            ... on bloc_images {
              images {
                id
                directus_files_id {
                  id
                }
              }
              crop
              lien
            }
            ... on bloc_texte {
              texte
            }
            ... on bloc_texteimage {
              texte
              image {
                id
              }
              document {
                id
              }
              link
              side
            }
            ... on bloc_documents {
              label
              documents {
                id
                directus_files_id {
                  id
                  title
                  filesize
                  type
                }
              }
            }
          }
        }
      }
  }
  `,
}
export default queries;
