import React from "react";
function CartoucheCarte({
    texte1='',
    texte2='',
    fontSize='30',
}) {
  return <div className="cartouche-carte">
      <div className="cartouche-carte-textes">
          <div className="shape-mask"></div>
          <div className="cartouche-carte-texte1" style={{fontSize:(fontSize*0.4)+'px'}}>{texte1}</div>
          <div className="cartouche-carte-texte2" style={{fontSize:(fontSize*0.225)+'px'}}>{texte2}</div>
      </div>
      <div className="dessin-carte"></div>
  </div>;
}

export default CartoucheCarte;
