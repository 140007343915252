const conf={
  apiUrl:'https://nptarn-admin.lefil.org',
  authToken: 'DDuqXMPRZFPe74QLai4c',
  special:[
    {id:6,class:'biocybele'},
    {id:19,class:'vin'},
    {id:8,class:'noctambio'},
  ],
  contactsApi:'https://contacts.nptarn.org',
  contactsApiKey:'s8xDF2rczsBY3YsgQ67x',
}
export default conf;
