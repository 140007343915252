import React, { useContext } from "react";
import SlugLink from 'components/Utils/SlugLink';
import CartoucheImage from 'components/Misc/CartoucheImage';
import Conf from 'utils/Conf';
import Grid from '@material-ui/core/Grid';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import BlocDocuments from 'components/Blocs/BlocDocuments';

import './TplGenerique.scss';
function TplGenerique({ data, fontSize, side }) {
  const { screenSize,t } = useContext(SettingsContext);
  const { titre, texte, articles, documents } = data;
  const factor=screenSize==="xs" || screenSize==="sm" ? 2.5 : 1 ;
  const blocTexte=<Grid item xs={12} md={6} className="generique-layout-0">
      {titre && <h3 style={{fontSize:(fontSize*0.2*factor)+'px'}}>{titre}</h3>}
      {texte && <div style={{fontSize:(fontSize*0.1*factor)+'px'}}>{Utils.parseWithLinks(texte)}</div>}
      {documents && documents.length>0 && <BlocDocuments item={{documents,label:t('a-telecharger')}}/>}
  </Grid>;
  return <div className={'generique-layout side-'+side}>
      <div className='generique-layout-inner'>
          <Grid container spacing={2}>
            {(side==='left' || screenSize==="xs" || screenSize==="sm") && blocTexte}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justify="center">
                {articles.map((article,i)=>{
                  return <Grid className="images-container" item xs={12} sm={6} key={i}>
                  <SlugLink item={article} type='article'>
                      <div className='generique-4-3'>
                          {article && <CartoucheImage texte={article.titre} url={article.visuel ? `${Conf.apiUrl}/assets/${article.visuel.id}?key=medium` : null } fontSize={fontSize*0.1*factor}/>}
                      </div>
                  </SlugLink>
                  </Grid>;
                })}
              </Grid>
            </Grid>
            {side==='right' && !(screenSize==="xs" || screenSize==="sm") && blocTexte}
          </Grid>
        </div>
      </div>;
}

export default TplGenerique;
