import React, { useEffect, useState } from "react";
import Conf from 'utils/Conf';
import Container from '@material-ui/core/Container';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet'
import './Carte.scss';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

function Carte() {
  const [ cas, setCas ] =useState([]);
  const [ selected, setSelected ] =useState(null);
  const [ preventClick,setPreventClick] = useState(false);
  useEffect(()=>{
    fetch(Conf.contactsApi+'/api.php?key='+Conf.contactsApiKey+'&q=getInstancesForm/4')
        .then(response => response.json())
        .then(data => setCas(data));
  },[]);
  useEffect(()=>{
    if (selected) document.body.style.overflow='hidden';
    else document.body.style.overflow='auto';
  },[selected]);
  return <><div className="carte">
  <div className="carte-inner">
  <MapContainer className="carte-map" center={[43.8, 2]} zoom={11} scrollWheelZoom={false} zoomControl={false}>
    <ZoomControl position='topright'/>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {cas.map((c,i)=><React.Fragment key={i}>
        {c.contact && c.contact.gps_x && c.contact.gps_y && <Marker className={c===selected ? 'selected':null} eventHandlers={{click:()=>setSelected(c)}} position={[c.contact.gps_y, c.contact.gps_x]}>
        </Marker>
        }
      </React.Fragment>
    )}
  </MapContainer>
  </div>
  </div>
  {selected && [selected].map((c)=>{
    const nom=(c.forms[0].instances[0].data.find((o)=>o.nom==='nom') || {valeur:''}).valeur;
    const prenom=(c.forms[0].instances[0].data.find((o)=>o.nom==='prenom') || {valeur:''}).valeur;
    const raison=(c.forms[0].instances[0].data.find((o)=>o.nom==='raison_soc') || {valeur:''}).valeur;
    const adresse=(c.forms[0].instances[0].data.find((o)=>o.nom==='ad') || {valeur:''}).valeur;
    const cp=(c.forms[0].instances[0].data.find((o)=>o.nom==='cp') || {valeur:''}).valeur;
    const ville=(c.forms[0].instances[0].data.find((o)=>o.nom==='ville') || {valeur:''}).valeur;
    const descriptif=(c.forms[0].instances[0].data.find((o)=>o.nom==='descriptif') || {valeur:''}).valeur;
    const diaporama=(c.forms[0].instances[0].data.find((o)=>o.nom==='diaporama') || {valeur:[]}).valeur;
    return <div key={c.id} className='pro-tile-backdrop' onClick={()=>!preventClick && setSelected(null)}>
      <div className='pro-tile' onClick={(e)=>e.stopPropagation()}>
      <div className='pro-diaporama'>
        <Swiper
        navigation
        pagination={{ clickable: true }}
        onTouchStart={()=>setPreventClick(true)}
        onTouchEnd={()=>setTimeout(()=>setPreventClick(false),10)}
        >
          {diaporama.map((image,i)=>{
            return <SwiperSlide key={image.url}>
              <img src={Conf.contactsApi+'/'+image.url} alt=""/>
            </SwiperSlide>
          })}
        </Swiper>
      </div>
      <div className='pro-content'>
        <h3>{prenom} <span className='nom'>{nom}</span></h3>
        <h4>{raison}</h4>
        <div className="pro-content-adresse">
          {adresse && <div>
          <div>{adresse}</div>
          {cp} {ville}
          </div>}
        </div>
        <div className="pro-content-descriptif">{descriptif}</div>
      </div>
    </div>
  </div>;})}
  </>;
}
export default Carte;
